







































import mixins from "vue-typed-mixins";
import SolidButton from "@/components/general/solid-button.vue";
import { ProfileMixin } from "@/mixins/participant-general-mixin";

export default mixins(ProfileMixin).extend({
  name: "ParticipantLowBottleBatteryModal",
  components: {
    SolidButton,
  },
  props: ["modalName"],
  mounted() {
    const percent_battery =
      this.dashboardData?.energy_gas_consumption_24_hours?.percent_battery;
    if (percent_battery == null) return;

    percent_battery <= 5 &&
      this.$store.commit("toggleModalState", {
        name: this?.modalName,
        status: true,
      });
  },
  computed: {
    isModalVisible: {
      get(): boolean {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
  methods: {
    onDismissModal() {
      this.closeModal(this.modalName);
    },
  },
});
