


































































import mixins from "vue-typed-mixins";

/** Mixins */
import { ProfileMixin } from "@/mixins/participant-general-mixin";

/** Components */
import ParticipantSmallInfoCard from "@/components/participant/participant-small-info-card.vue";

export default mixins(ProfileMixin).extend({
  name: "MeasurementsElectricHouseHeading",
  components: {
    ParticipantSmallInfoCard,
  },
  computed: {
    getCapacityInLiters: function (): string {
      if (
        !this?.dashboardData?.energy_gas_consumption_24_hours ||
        !this?.dashboardData?.energy_gas_consumption_24_hours?.volume
      )
        return "Por determinar";

      const { volume } = this?.dashboardData?.energy_gas_consumption_24_hours;
      return `≈${volume} litros`;
    },
    getGasCapacity: function (): number {
      if (!this?.dashboardData?.energy_gas_consumption_24_hours) return 0.0;

      const { percent } = this?.dashboardData?.energy_gas_consumption_24_hours;
      return percent ?? 0.0;
    },
    getGasCapacityIconColor(): string {
      const bottleCapacity = this.getGasCapacity;

      if (bottleCapacity === 0) return "gray-2";
      if (bottleCapacity > 50) return "green-2";
      if (bottleCapacity > 10) return "orange-3";
      return "red-3";
    },
    getAvarageConsumption: function (): number {
      const avgConsunption =
        this?.dashboardData?.energy_gas_consumption_24_hours
          ?.average_consumption;
      return avgConsunption ?? 0;
    },
    mostConsumingApplianceSubtitle() {
      const { subtitle } = this.getMostConsumingApplianceDetails();
      return subtitle;
    },
    mostConsumingApplianceConsumption() {
      const { consumption } = this.getMostConsumingApplianceDetails();
      return consumption;
    },
  },
});
