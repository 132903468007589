import { render, staticRenderFns } from "./participant-low-bottle-battery-modal.vue?vue&type=template&id=3170caaf&scoped=true&"
import script from "./participant-low-bottle-battery-modal.vue?vue&type=script&lang=ts&"
export * from "./participant-low-bottle-battery-modal.vue?vue&type=script&lang=ts&"
import style0 from "./participant-low-bottle-battery-modal.vue?vue&type=style&index=0&id=3170caaf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3170caaf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VDialog,VIcon})
