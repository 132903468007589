







































import mixins from "vue-typed-mixins";
import SolidButton from "@/components/general/solid-button.vue";
import { ProfileMixin } from "@/mixins/participant-general-mixin";

export default mixins(ProfileMixin).extend({
  name: "ParticipantLowGasModal",
  components: {
    SolidButton,
  },
  props: ["modalName"],
  mounted() {
    const percent =
      this.dashboardData?.energy_gas_consumption_24_hours?.percent;
    if (!percent) return;

    (percent === 10 || percent === 20) &&
      this.$store.commit("toggleModalState", {
        name: this?.modalName,
        status: true,
      });
  },
  computed: {
    isModalVisible: {
      get(): boolean {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
  methods: {
    onDismissModal() {
      this.closeModal(this.modalName);
    },
  },
});
