import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "../../store/index";
import customAxios from "@/axios-service";
import config from "@/config";

export interface ConsumptionData {
  day_before: {
    energy_consumption_24_hours: {
      profile: { x: string; y: string }[];
      total: number;
      date: string;
    };
    appliance_consumption_24_hours: {
      id: number;
      sensor: string;
      device_type_id: number;
      device_type_name: string;
      color?: string;
      energy: number;
      profile: { x: string; y: string }[];
    }[];
  };
  week_before: {
    appliances?: {
      id: number;
      sensor: string;
      device_type_id: number;
      device_type_name: string;
      color?: string;
      profile: { x: string; y: string }[];
    }[];
    mains: {
      end: string;
      start: string;
      total: number;
      profile: {
        [key: string]: {
          x: string;
          y: string;
        }[];
      };
    };
  };
  annual: {
    appliances?: {
      id: number;
      sensor: string;
      device_type_id: number;
      device_type_name: string;
      color?: string;
      profile: { x: string; y: string }[];
    }[];
    mains: {
      end: string;
      start: string;
      total: number;
      profile: {
        [key: string]: {
          x: string;
          y: string;
        }[];
      };
    };
  };
}
export interface Appliance {
  color: string;
  device_type_id: number;
  device_type_name: string;
  energy: number;
  friendly_name: string;
  id: number;
  profile: { x: string; y: string }[];
  sensor: string;
}
export interface ProductionData {
  has_production_meter: false;
  consumption: ConsumptionData;
  production: ConsumptionData;
  injection: ConsumptionData;
}
export interface ApplianceInfo {
  [key: number]: {
    color: string;
    icon: string;
    name: string;
  };
}

function fixNullDataValues(ar: any[], cond?: boolean): void {
  if (ar) {
    ar.map((obj: any) => {
      if (cond && !obj.y) {
        obj.y = 0;
      }
      return obj;
    });
  }
}

function hexToRgb(hex: string, opacity = 1): string {
  //slice the # from hex string
  const bigint = parseInt(hex.slice(1, hex.length), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r},${g},${b},${opacity})`;
}

export interface ConsumptionInfo {
  consumption_info: Nullable<ConsumptionData>;
  production_info: Nullable<ProductionData>;
}

export const state: ConsumptionInfo = {
  consumption_info: JSON.parse(
    sessionStorage.getItem("consumption-info") || "null"
  ),
  production_info: JSON.parse(
    sessionStorage.getItem("production-info") ||
      '{"consumption":null,"production":null,"injection":null}'
  ) as ProductionData,
};

const getters: GetterTree<ConsumptionInfo, RootState> = {
  consumptionInfo: (state) => {
    return state.consumption_info;
  },

  productionInfo: (state) => (param: any) => {
    if (param == "production") {
      return state.production_info?.production;
    } else if (param == "consumption") {
      return state.production_info?.consumption;
    } else if (param == "injection") {
      return state.production_info?.injection;
    } else {
      return state.production_info?.has_production_meter;
    }
  },
};

const mutations: MutationTree<ConsumptionInfo> = {
  updateConsumptionInfo: (state, value: ConsumptionData) => {
    state.consumption_info = value;
    sessionStorage.setItem(
      "consumption-info",
      JSON.stringify(state.consumption_info)
    );
  },
  updateProductionInfo: (state, value: ProductionData) => {
    state.production_info = value;
    sessionStorage.setItem(
      "production-info",
      JSON.stringify(state.production_info)
    );
  },
};

const actions: ActionTree<ConsumptionInfo, RootState> = {
  async getConsumptionInfo({ commit }, extra: any) {
    const fixData = fixNullDataValues;
    const hexRgb = hexToRgb;
    const applianceConfig = config.applianceInfo;

    const params = extra;

    return customAxios
      .get(`/api/${extra.uri}`, { params })
      .then((response) => {
        if (response.data) {
          if (response.data.day_before?.energy_consumption_24_hours) {
            fixData(
              response.data.day_before.energy_consumption_24_hours.profile,
              !!response.data.day_before.energy_consumption_24_hours
            );
          }
          if (
            response.data.week_before &&
            response.data.week_before.mains.profile
          ) {
            const weekData = response.data.week_before.mains;
            const profileIDs = Object.keys(weekData.profile);
            profileIDs.forEach((id) => {
              fixData(weekData.profile[id]);
            });
          }
          if (response.data.annual && response.data.annual.mains.profile) {
            const annualData = response.data.annual.mains;
            const profileIDs = Object.keys(annualData.profile);
            profileIDs.forEach((id) => {
              fixData(annualData.profile[id]);
            });
          }

          if (response.data.day_before?.appliance_consumption_24_hours) {
            const applianceList =
              response.data.day_before?.appliance_consumption_24_hours;
            const counter = {} as any;
            const updateNames = {} as any;
            applianceList.forEach((appliance: any) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              const curColor = applianceConfig[appliance.device_type_id]?.color;
              // Maybe change the default color this turns into ?
              appliance.color = curColor ? curColor : "#a0a0a0";
              if (counter[appliance.device_type_id]) {
                counter[appliance.device_type_id] =
                  counter[appliance.device_type_id] + 1;
                const baseName = appliance.device_type_name;
                const friendlyName = appliance.friendly_name;
                appliance.device_type_name = friendlyName
                  ? `${baseName} - ${friendlyName}`
                  : `${baseName} - ${counter[appliance.device_type_id]}`;
                appliance.color = hexRgb(
                  curColor,
                  1 - 0.3 * (counter[appliance.device_type_id] - 1)
                );
                updateNames[appliance.id] = {
                  name: appliance.device_type_name,
                  color: appliance.color,
                };
              } else {
                counter[appliance.device_type_id] = 1;
              }
            });

            // this is to fix first cases which were skipped due to counting on top
            // this also included the fix to always display type "Others" with the friendly name in case it has
            // last fix happens only when that type is singular as repeated events fix this with default behaviour
            applianceList.forEach((appliance: any) => {
              if (
                (counter[appliance.device_type_id] > 1 &&
                  appliance.color[0] === "#") ||
                (counter[appliance.device_type_id] === 1 &&
                  appliance.device_type_id === 12)
              ) {
                const baseName = appliance.device_type_name;
                const friendlyName = appliance.friendly_name;
                appliance.device_type_name = friendlyName
                  ? `${baseName} - ${friendlyName}`
                  : `${baseName} - 1`;
                updateNames[appliance.id] = {
                  name: appliance.device_type_name,
                  color: appliance.color,
                };
              }
            });

            const weeklyAppliances = response.data.week_before?.appliances;
            const anualAppliances = response.data.annual?.appliances;

            if (weeklyAppliances) {
              weeklyAppliances.forEach((appliance: Appliance) => {
                const { device_type_id } = appliance;
                const appliances: ApplianceInfo = config.applianceInfo;

                const curColor = appliances[device_type_id]?.color;

                appliance.color = curColor ? curColor : "#a0a0a0";
                if (updateNames[appliance.id]) {
                  appliance.device_type_name = updateNames[appliance.id].name;
                  appliance.color = updateNames[appliance.id].color;
                }
              });
            }

            if (anualAppliances) {
              anualAppliances.forEach((appliance: any) => {
                const { device_type_id } = appliance;
                const appliances: ApplianceInfo = config.applianceInfo;

                const curColor = appliances[device_type_id]?.color;

                appliance.color = curColor ? curColor : "#a0a0a0";
                if (updateNames[appliance.id]) {
                  appliance.device_type_name = updateNames[appliance.id].name;
                  appliance.color = updateNames[appliance.id].color;
                }
              });
            }
          }
        }

        if (extra.uri == "consumption_energy") {
          return commit("updateConsumptionInfo", response.data);
        } else {
          return commit("updateProductionInfo", response.data);
        }
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
};

const namespaced = true;

const consumptionData: Module<ConsumptionInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default consumptionData;
