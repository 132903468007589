import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "../../store/index";
import customAxios from "@/axios-service";

export interface ActivityProject {
  id: number;
  is_new: boolean;
  title: string;
  short_description: string;
  long_description: string;
  term_and_condition_description: string;
  total_credit: number;
  duration: number;
  commencement_date: string;
  submission_end_date: string;
  conclusion_date: string;
  completed_tasks: number;
  tasks: Array<ActivityTask>;
  event_launch_start_date: string;
  event_launch_end_date: string;
  display_order: number;
  is_general_project: boolean;
  number_of_task: number;
  social_channel: string;
  survey_form: string;
}

export interface ActivityForm {
  id: number;
  link_id: string;
}

export interface ActivityTaskSubmission {
  id: number;
  activity_form_response: number;
  activity_task: number;
  comment: string;
  credit: number;
  last_submission_date: string;
  participant: number;
  validation_state: string;
}

export interface ActivityTask {
  id: number;
  title: string;
  short_description: string;
  credit: number;
  display_order: number;
  task_category: number;
  activity_project: ActivityProject;
  activity_form: ActivityForm;
  submission: ActivityTaskSubmission;
}

export interface GeneralUpdate {
  activity_project: number;
  activity_general: number;
  activity_total: number;
  completed_project: ActivityProjectHistory;
}

export interface Activity {
  count: number;
  next: string;
  previous: string;
  results: Array<ActivityProject>;
}

export interface ActivityTaskHistory {
  id: number;
  activity_task: ActivityTask;
  credit: number;
  validation_state: string;
  participant: number;
}

export interface ActivityProjectHistory {
  id: number;
  tasks: Array<ActivityTask>;
  activity_project: ActivityProject;
  validation_state: string;
  participant: number;
}

export interface ActivityInfo {
  general_tasks: Nullable<Activity>;
  general_tasks_new: Nullable<Activity>;
  activity_history_projects: Nullable<ActivityProjectHistory[]>;
  activity_history_tasks: Nullable<ActivityTaskHistory[]>;
  activity_project: Nullable<ActivityProject[]>;
  activity_project_new: Nullable<ActivityProject[]>;
  general_update: Nullable<GeneralUpdate>;
  activity_ongoing_project: Nullable<ActivityProject[]>;
  notificationTask: Nullable<Activity[]>;
  history_new: number | Nullable<number>;
}

export const state: ActivityInfo = {
  general_tasks_new: null,
  activity_project_new: null,
  general_tasks: null,
  activity_history_projects: null,
  activity_history_tasks: null,
  activity_project: null,
  activity_ongoing_project: null,
  general_update: null,
  notificationTask: null,
  history_new: null,
};

const getters: GetterTree<ActivityInfo, RootState> = {
  getActivityOnGoingProject: (state) => {
    return state.activity_ongoing_project;
  },
  generalTasks: (state) => {
    return state.general_tasks;
  },
  getHistoryNew: (state) => {
    return state.history_new;
  },
  generalTasksNew: (state) => {
    return state.general_tasks_new;
  },

  activeProjectHistory: (state) => {
    return state.activity_history_projects;
  },
  activeTaskHistory: (state) => {
    return state.activity_history_tasks;
  },
  activityProjects: (state) => {
    return state.activity_project;
  },
  activityProjectsNew: (state) => {
    return state.activity_project_new;
  },
  generalUpdate: (state) => (id: any) => {
    //choose 1 for tasks 2 for projects and 3 for the total
    return id == 1
      ? state.general_update?.activity_general
      : id == 2
      ? state.general_update?.activity_project
      : id == 3
      ? state?.general_update?.activity_total
      : id == 4
      ? state?.general_update?.completed_project
      : state.general_update;
  },
  projectCompletionModal: (state) => {
    return state.general_update
      ? state.general_update.completed_project
      : ({} as ActivityProjectHistory);
  },
  notificationTask: (state) => {
    return state.notificationTask;
  },
};

const mutations: MutationTree<ActivityInfo> = {
  updateRemoveTask: (state, value: any) => {
    if (
      state.general_update ? state.general_update?.activity_general > 0 : null
    )
      state.general_update
        ? (state.general_update.activity_general =
            state.general_update.activity_general - value)
        : null;
  },
  updateHistoryNew: (state, value: any) => {
    if (value == 0) {
      state.history_new = 0;
    }
    state.history_new = state.history_new + value;
  },
  updateRemoveProject: (state, value: any) => {
    if (
      state.general_update ? state.general_update?.activity_project > 0 : null
    ) {
      state.general_update
        ? (state.general_update.activity_project =
            state.general_update.activity_project - value)
        : null;
    }
  },
  resetActivityData2: (state) => {
    state.general_tasks = null;

    state.activity_project = null;

    state.activity_history_projects = null;

    state.activity_history_tasks = null;
    state.general_update = null;
  },

  updateGeneralTasks: (state, value: any) => {
    state.general_tasks = value;
  },

  updateNotificationsTask: (state, value: any) => {
    state.notificationTask = value;
  },

  updateGeneralTasksNew: (state, value: any) => {
    state.general_tasks_new = value;
  },
  updateProjectHistory: (state, value: any) => {
    state.activity_history_projects = value;
  },

  updateTaskHistory: (state, value: any) => {
    state.activity_history_tasks = value;
  },

  updateActivityProject: (state, value: any) => {
    state.activity_project = value;
  },
  updateActivityProjectNew: (state, value: any) => {
    state.activity_project_new = value;
  },

  updateOnGoingProject: (state, value: any) => {
    state.activity_ongoing_project = value;
  },

  updateNots: (state, value: any) => {
    state.general_update = value;
  },
};

const actions: ActionTree<ActivityInfo, RootState> = {
  async getProjectHistory({ commit }) {
    return customAxios
      .get(`/api/activity_project_history`)
      .then((response) => {
        return commit("updateProjectHistory", response.data.results);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async getTaskHistory({ commit }) {
    return customAxios
      .get(`/api/activity_task_history`)
      .then((response) => {
        return commit("updateTaskHistory", response.data.results);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async getOnGoingProject({ commit }) {
    return customAxios
      .get(`/api/activity_project/project/ongoing/0`)
      .then((response) => {
        return commit("updateOnGoingProject", response.data.results);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async getActivityProjects({ commit }) {
    return customAxios
      .get(`/api/activity_project/project/visual/0`)
      .then((response) => {
        return commit("updateActivityProject", response.data.results);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async setSeenGeneralTask({ dispatch }, data: any) {
    return customAxios({
      method: "post",
      url: "/api/activity_project_update/general/InProgress",
      data: {
        update_id: data,
      },
    })
      .then((response) => {
        dispatch("getGeneralTasks");
      })
      .catch((e) => {
        return dispatch("formResponseError", e);
      });
  },
  async setFinalisedProject({ dispatch }, data: any) {
    return customAxios({
      method: "post",
      url: "/api/activity_project_update/project/Finalized",
      data: {
        update_id: data,
      },
    }).then(() => {
      return dispatch("getActivityProjects");
    });
  },
  async setActiveProj({ dispatch }, data: any) {
    return customAxios({
      method: "post",
      url: "/api/activity_project_update/project/Active",
      data: {
        update_id: data,
      },
    })
      .then(() => {
        return dispatch("getActivityProjects").then(() => {
          return dispatch("getOnGoingProject");
        });
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async setSeenProject({ dispatch }, data: any) {
    return customAxios({
      method: "post",
      url: "/api/activity_project_update/project/Viewed",
      data: {
        update_id: data,
      },
    })
      .then(() => {
        return dispatch("getActivityProjects");
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async getGeneralTasks({ commit }) {
    return customAxios
      .get(`/api/activity_project/general/visual/0`)
      .then((response) => {
        return commit("updateGeneralTasks", response.data.results);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  /** Fetch General Tasks */
  async getGeneralTasksNew({ commit }) {
    return customAxios
      .get(`/api/activity_project/all/dashboard/0`)
      .then((response) => {
        return commit("updateGeneralTasksNew", response.data.results);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async getNotificationsTask({ commit }) {
    return customAxios
      .get(`/api/activity_project/all/dashboard/1`)
      .then((response) => {
        return commit("updateNotificationsTask", response.data.results);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async getNotsProjAct({ commit }) {
    return customAxios
      .get(`/api/general_update`)
      .then((response) => {
        return commit("updateNots", response.data);
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
};

const namespaced = true;

const activityData2: Module<ActivityInfo, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default activityData2;
