



































































































































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";

export default mixins(GeneralMixin).extend({
  name: "GasConsumptionCard",
  components: { ParticipantNoContentInfoCard },
  props: ["cardTitle"],
  computed: {
    hasHumidityTemperatureData(): boolean {
      return this.dashboardData?.real_time_ht?.length;
    },
  },
});
