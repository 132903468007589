import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";

const ProfileMixin = mixins(GeneralMixin).extend({
  computed: {
    profile_info: {
      get(): any {
        return this.$store.getters["participantGeneral/profileInfo"];
      },
    },
    dashboardData: {
      get(): any {
        return this.$store.getters["dashboardData/dashboardInfo"];
      },
    },
  },
  methods: {
    getMostConsumingApplianceDetails(): {
      subtitle: string;
      consumption: number;
    } {
      const { energy, device_type_name } =
        this.dashboardData?.most_consuming_appliance ?? {};

      return {
        subtitle: device_type_name ?? "Por determinar",
        consumption: energy ?? 0,
      };
    },
    getApplianceIcon(): string {
      if (this.dashboardData.most_consuming_appliance) {
        const deviceId: number =
          this.dashboardData.most_consuming_appliance.device_type_id;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const iconName: string = config.applianceInfo[deviceId]?.icon;
        if (!iconName) {
          return "";
        }
        return `appliances/${iconName}`;
      }
      return "";
    },
    getActivityTime(): string {
      return this.dashboardData.energy_consumption_24_hours?.most_activity_hour
        ? this.dashboardData.energy_consumption_24_hours?.most_activity_hour
        : "00:00";
    },
    async runParentAction(): Promise<any> {
      this.$store.commit("formRecord/updateActiveForm", {
        already_submitted: false,
      });
      await this.$store.dispatch("participantGeneral/getProfileInfo");
      return true;
    },
  },
});

export { ProfileMixin };
export default {};
