




























import config from "@/config";
import NavButtonsInfo from "@/components/participant/nav-buttons-info.vue";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";

export default mixins(GeneralMixin).extend({
  name: "DashboardEquipmentsCard",
  components: { NavButtonsInfo, ParticipantNoContentInfoCard },
  props: [
    "icon",
    "size",
    "cardTitle",
    "messageTitle",
    "messageText",
    "roughEdges",
    "page_size",
    "history",
  ],
  data() {
    return {
      check_btn: true,
      deviceInfo: config.monitorDeviceInfo as any,
      meterInfo: config.energyMeterInfo as any,
      prodInfo: config.productionMeterInfo as any,
      gasInfo: config.gasMeterInfo as any,
    };
  },
  methods: {
    changeState_ht() {
      this.check_btn = false;
    },
    changeState_e() {
      this.check_btn = true;
    },
    getDeviceSubtitle(device: any): string {
      // device id was removed from here. Keeping this comment here just to remind me
      return `${this.deviceInfo[device.device_category].name}`;
    },
    getDeviceTitle(device: any) {
      if (
        (device.device_type_id === 8 || device.device_category === 3) &&
        device.device_manufacture_display_name
      ) {
        return device.device_manufacture_display_name;
      }

      return device.device_type_name;
    },
    mergeEnergyMeterHistory() {
      /** Merge history items from index 0 and 1 (energy meters */
      if (this.history?.[0]?.items && this.history?.[1]?.items) {
        return {
          ...this.history[0],
          items: [...this.history[0].items, ...this.history[1].items],
        };
      }
    },
    getMergedHistoryItems() {
      const mergedEnergyMetersHistory = this.mergeEnergyMeterHistory();

      if (this.history && this.check_btn) {
        return mergedEnergyMetersHistory?.items || null;
      }

      return this.history?.[2]?.items || null;
    },
  },
});
