




























import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";

/** Components */
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import ParticipantMonitorNewCard from "@/components/participant/participant-monitor-new-card.vue";
import ParticipantLowBottleBatteryModal from "@/components/participant/modals/participant-low-bottle-battery-modal.vue";

export default mixins(GeneralMixin).extend({
  name: "ParticipantMonitores",
  components: {
    ParticipantNoContentInfoCard,
    ParticipantMonitorNewCard,
    ParticipantLowBottleBatteryModal,
  },
  data() {
    return {
      updateMonitorMethod: 0,
      monitorInfo: config.monitorInfo,
    };
  },
  created() {
    this.retrieveMonitorData(true);
    this.getCharacterizationForms();

    // Currently this is 10 minutes (in milliseconds)
    this.updateMonitorMethod = setInterval(this.retrieveMonitorData, 300000);
  },
  beforeMount() {
    if (this.isEv4eu) {
      this.$router.push("/access/blocked");
    }
  },
  beforeDestroy() {
    clearInterval(this.updateMonitorMethod);
  },
  computed: {
    monitors: {
      get(): string {
        return this.$store.getters["monitorData/monitorInfo"];
      },
    },
    isParticipantWithEnergyGasMeter(): boolean {
      return this.dashboardData?.has_energy_gas_meter ?? false;
    },
  },
  methods: {
    async retrieveMonitorData(initial = false) {
      if (initial) {
        this.$store.commit("updateState", { loading_overlay: true });
      }
      await this.$store.dispatch("monitorData/getMonitorInfo");
      if (initial) {
        this.$store.commit("updateState", { loading_overlay: false });
      }
    },
    async getCharacterizationForms(): Promise<void> {
      this.$store.dispatch("formsData/getCharacterizationForms");
    },
  },
});
