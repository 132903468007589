






























































































































































































import mixins from "vue-typed-mixins";
import { ProfileMixin } from "@/mixins/participant-general-mixin";
import NotificationsModal from "../notifications-dropdown.vue";

export default mixins(ProfileMixin).extend({
  components: { NotificationsModal },
  name: "ParticipantProfileBar",
  props: ["profileInfo"],
  data() {
    return {
      show_menu: false,
      show_dropdown: false,
    };
  },
  created() {
    this.getProfileInfo();
  },
  computed: {
    notificationTask: {
      get(): any {
        return this.$store.getters["activityData2/notificationTask"];
      },
    },
    projectsTaskSize: {
      get(): any {
        return this.$store.getters["activityData2/generalUpdate"](3);
      },
    },
  },

  methods: {
    click(a: any) {
      return (this.show_dropdown = a), (this.show_menu = false);
    },
    async getProfileInfo(): Promise<void> {
      if (!this.profile_info) {
        await this.$store.dispatch("participantGeneral/getProfileInfo");
      }
    },
    displayPasswordModal(): void {
      this.$store.commit("toggleModalState", {
        name: "password",
        status: true,
      });
      if (!this.$route.path.includes("profile")) {
        this.$router.push({ path: "/participant/profile" });
      }
    },
    supportModalAction(open: boolean): void {
      this.$store.commit("toggleModalState", {
        name: "support",
        status: open,
      });
      if (!this.$route.path.includes("terms-conditions")) {
        this.$router.push({ path: "/participant/terms-conditions" });
      }
    },
    showMenu(): void {
      this.show_menu = !this.show_menu;
    },
    closeMenu(e: any): void {
      if (!e.target.className.includes("dropdown-component")) {
        this.show_menu = false;
      }
    },
  },
});
