














































import mixins from "vue-typed-mixins";

/** Mixins */
import { ProfileMixin } from "@/mixins/participant-general-mixin";

/** Components */
import ParticipantSmallInfoCard from "@/components/participant/participant-small-info-card.vue";

export default mixins(ProfileMixin).extend({
  name: "MeasurementsHeading",
  components: {
    ParticipantSmallInfoCard,
  },
  computed: {
    mostConsumingApplianceSubtitle() {
      const { subtitle } = this.getMostConsumingApplianceDetails();
      return subtitle;
    },
    mostConsumingApplianceConsumption() {
      const { consumption } = this.getMostConsumingApplianceDetails();
      return consumption;
    },
  },
});
