var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    Object.keys(_vm.info).length != 0 || Object.keys(_vm.info.tasks[0]).length == 0
  )?_c('div',[(
      _vm.size_activity != 0 &&
      _vm.info.is_new == true &&
      _vm.notReady == false &&
      _vm.fromHistory == false
    )?_c('div',{staticClass:"\n      new-notification\n      d-flex\n      align-center\n      rounded-b-0 rounded-lg\n      pl-5\n      strong-text\n    "},[_vm._v(" Nova Tarefa ")]):_vm._e(),(_vm.info)?_c('v-row',{staticClass:"task-card align-center d-none d-lg-flex mb-3 mx-0",class:_vm.notReady == false && _vm.size_activity > 0 && _vm.info.isNewTask == true
        ? 'rounded-t-0 rounded-lg'
        : 'rounded-lg'},[_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"mb-2 header-7 strong-text"},[_vm._v(_vm._s(_vm.taskTitle))]),_c('p',{staticClass:"mb-0 text-x pr-2"},[_vm._v(_vm._s(_vm.taskDescription))]),(_vm.fromHistory)?_c('div',{staticClass:"d-flex align-center mb-0 mt-2 green-content strong-text",staticStyle:{"font-size":"16px"}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-wallet.svg"),"alt":"credit-wallet"}}),_c('p',{staticClass:"ml-2 mb-0",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.taskCredits)+" créditos atribuídos ")])]):_vm._e(),(_vm.isExternalTaskVisible)?_c('div',{staticClass:"d-flex pt-4"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/icons/icon-information.png"),"height":"18px","width":"18px","alt":"Icon Informação"}}),_c('p',{staticClass:"text-x error-text semi-strong-text"},[_vm._v(" Esta atividade vai direcionar-te para um link externo ao Living Energy, para realizar a tarefa clica no botão "),_c('b',[_vm._v("Participar")]),_vm._v(". ")])]):_vm._e()]),(!(_vm.fromHistory && _vm.getButtonState() === 'Completar Tarefa'))?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"4"}},[(_vm.isExternalTaskVisible)?_c('solid-button',{staticClass:"mb-2",attrs:{"buttonTextClass":'button-simple-text',"color":_vm.getParticipateTaskButtonColor,"caption":'Participar',"fromHistory":_vm.fromHistory,"disabled":_vm.validation &&
          _vm.validation !== 'Available' &&
          _vm.validation !== 'In Progress'},on:{"buttonClick":function($event){return _vm.handleExternalTaskOpening()}}}):_vm._e(),_c('solid-button',{attrs:{"buttonClass":_vm.getTaskButtonClass,"buttonTextClass":_vm.getTaskButtonTextClass,"caption":_vm.getButtonState(),"color":_vm.getCompleteTaskButtonColor,"fromHistory":_vm.fromHistory,"outlined":_vm.shouldOutlineButton,"disabled":_vm.isTaskDisabled},on:{"buttonClick":function($event){return _vm.handleTaskCompletion()}}})],1):_vm._e()],1):_vm._e(),(_vm.info)?_c('v-row',{staticClass:"\n      px-1\n      mx-0\n      mb-2\n      mt-0\n      py-0\n      task-card\n      d-flex\n      align-center\n      d-block d-lg-none\n    ",class:_vm.notReady == false && _vm.size_activity > 0 && _vm.info.isNewTask == true
        ? 'rounded-t-0 rounded-lg'
        : 'rounded-lg'},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"header-7 strong-text"},[_vm._v(_vm._s(_vm.taskTitle)+" ")])]),_c('v-col',{staticClass:"pb-2",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.taskDescription)+" ")])]),(_vm.isGeneral == true && _vm.fromHistory == true)?_c('v-col',{staticClass:"strong-text green-content d-flex align-center",staticStyle:{"font-size":"16px"},attrs:{"cols":"12"}},[_c('img',{staticClass:"pr-1",staticStyle:{"font-size":"14px"},attrs:{"src":require("@/assets/icons/icon-wallet.svg"),"alt":"credit-wallet"}}),_c('span',[_vm._v(" "+_vm._s(_vm.taskCredits)+" créditos atribuídos")])]):_vm._e(),(_vm.isExternalTaskVisible)?_c('v-col',{staticClass:"d-flex flex-row pt-4"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/icons/icon-information.png"),"height":"18px","width":"18px","alt":"Icon Informação"}}),_c('p',{staticClass:"text-x error-text semi-strong-text"},[_vm._v(" Esta atividade vai direcionar-te para um link externo ao Living Energy, para realizar a tarefa clica no botão "),_c('b',[_vm._v("Participar")]),_vm._v(". ")])]):_vm._e(),(!(_vm.fromHistory && _vm.getButtonState() === 'Completar Tarefa'))?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[(_vm.isExternalTaskVisible)?_c('solid-button',{staticClass:"mb-2",attrs:{"buttonTextClass":'button-simple-text',"color":_vm.getParticipateTaskButtonColor,"caption":'Participar',"disabled":_vm.validation &&
          _vm.validation !== 'Available' &&
          _vm.validation !== 'In Progress'},on:{"buttonClick":function($event){return _vm.handleExternalTaskOpening()}}}):_vm._e(),_c('solid-button',{attrs:{"buttonClass":_vm.getTaskButtonClass,"buttonTextClass":_vm.getTaskButtonTextClass,"caption":_vm.getButtonState(),"color":_vm.getCompleteTaskButtonColor,"fromHistory":_vm.fromHistory,"outlined":_vm.shouldOutlineButton,"disabled":_vm.isTaskDisabled},on:{"buttonClick":function($event){return _vm.handleTaskCompletion()}}})],1):_vm._e()],1):_vm._e(),_c('form-modal',{attrs:{"formClass":'secondary-form change-password-form',"formLink":_vm.getFormLink,"modalTitle":'Completar Tarefa',"modalName":_vm.modalName,"onCompletedActionOveride":'close_modal|' + _vm.modalName + '\r\nreload'}}),_c('participant-support-success-modal',{attrs:{"modalImage":'activity/icon-completed-task.svg',"modalName":_vm.taskCompleted + _vm.secondaryStyle,"modalTitle":'Completar Tarefa',"modalContent":_vm.taskCompletedContent,"modalButtonLabel":'Fechar'},on:{"callParentAction":function($event){return _vm.closeModal(_vm.taskCompleted + _vm.secondaryStyle)}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }