












































































































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import solidButton from "./solid-button.vue";

export default mixins(GeneralMixin).extend({
  components: { solidButton },
  name: "ProjectDetailsModal",
  props: ["project", "isOnCourse", "isGeneral", "modalName"],
  data() {
    return {
      checked: false,
      hasScrolledToBottom: false,
      showRedirectLoader: false,
    };
  },
  watch: {
    modalShow: function (val: boolean): void {
      if (val && this.project?.long_description?.length <= 500) {
        this.hasScrolledToBottom = true;
      }
    },
  },
  methods: {
    checkCorrect() {
      this.checked = !this.checked;
    },
    onStartActivity(): void {
      let url: string | null = null;
      const { task_redirect_url, is_external_task, activity_form } =
        this.project?.tasks?.[0] ?? {};

      /** Handle external URL redirection */
      if (is_external_task && task_redirect_url?.startsWith("https://")) {
        const queryParams = activity_form?.form_access_link
          ? `?access_code=${activity_form.form_access_link}`
          : "";
        url = `${task_redirect_url}${queryParams}`;
      } else if (!is_external_task && task_redirect_url) {
        /** Handle internal URL redirection */
        const parsedUrl = new URL(task_redirect_url);
        url = parsedUrl.pathname;
      }

      if (task_redirect_url) {
        this.showRedirectLoader = true;

        setTimeout(() => {
          this.onSetProjectActive();
          /** Fetch the updated task notification */
          this.$store.dispatch("activityData2/getNotsProjAct");

          if (is_external_task && url) {
            window.open(url, "_blank");
            return;
          }
          if (!is_external_task && url) {
            this.$router.push({ path: url.startsWith("/") ? url : `/${url}` });
          }
        }, 3000);
        return;
      }
      this.onSetProjectActive();
    },
    onCloseActivityDetailsModal() {
      this.onSetProjectActive();
    },
    handleScroll: function (el: any) {
      if (
        el.srcElement.offsetHeight + el.srcElement.scrollTop >=
        el.srcElement.scrollHeight - 1
      ) {
        this.hasScrolledToBottom = true;
      }
    },
    onSetProjectActive(): void {
      this.$store.dispatch("activityData2/setActiveProj", this.project.id);
      this.closeModal(this.modalName);
    },
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
        /** On close dismiss/close model we reset the checked and hasScrolledToBottom status  */
        const el = document.getElementById("terms-and-conditions--container");
        if (el) {
          el.scrollTop = 0;
        }
        this.checked = false;
        this.hasScrolledToBottom = false;
      },
    },
  },
});
