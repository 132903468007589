











































































































































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import solidButton from "@/components/general/solid-button.vue";
import FormModal from "@/components/general/form-modal.vue";
import ParticipantSupportSuccessModal from "../participant-support-success-modal.vue";
import config from "@/config";

export default mixins(GeneralMixin).extend({
  components: { solidButton, FormModal, ParticipantSupportSuccessModal },
  name: "TaskCard",
  data() {
    return {
      test: true,
      taskCompleted: "modal-completed-task-",
      successModal: "c-task-",
      modalName: "",
    };
  },
  props: {
    validation: {
      type: String,
    },
    secondaryStyle: {
      required: false,
    },
    fromHistory: {
      required: false,
      type: Boolean,
      default: false,
    },
    isGeneral: {
      required: false,
      type: Boolean,
      default: false,
    },
    notReady: {
      required: false,
      type: Boolean,
      default: false,
    },
    subTaskBtn: Function,
    info: {
      required: false,
      type: Object as () => any,
      default: {},
    },
  },
  computed: {
    getValidationStatus(): string {
      return this.validation?.toLocaleLowerCase().replaceAll(" ", "-");
    },
    taskTitle(): string | null {
      const { fromHistory, isGeneral, info } = this;
      return fromHistory == true && isGeneral == true
        ? info.activity_task.title
        : isGeneral == true
        ? info.tasks[0]
          ? info.tasks[0].title
          : null
        : info.title;
    },
    taskDescription(): string | null {
      const { fromHistory, isGeneral, info } = this;
      return fromHistory == true && isGeneral == true
        ? info.activity_task.short_description
        : isGeneral == true
        ? info.tasks[0]
          ? info.tasks[0].short_description
          : null
        : info.short_description;
    },
    taskCredits(): number {
      const { fromHistory, isGeneral, info } = this;
      const credits =
        fromHistory == true && isGeneral == true
          ? info.activity_task.credit
          : isGeneral == true
          ? this.info.tasks[0].credit
          : info.credit;

      return credits ?? 0;
    },
    isExternalTaskVisible(): boolean {
      if (this.validation === "For Review" || this.fromHistory) {
        return false;
      }

      return this.info?.is_external_task && this.info?.task_redirect_url;
    },
    taskCompletedContent: {
      get(): any {
        return this.isGeneral
          ? config.activityTaskCompleted.general
          : config.activityTaskCompleted.specific;
      },
    },
    size_activity: {
      get(): any {
        return this.$store.getters["activityData2/generalUpdate"](1);
      },
    },
    taskRedirectUrl: {
      get(): string {
        const { is_external_task, task_redirect_url, activity_form } =
          this.info ?? {};
        const queryParams = activity_form?.form_access_link
          ? `?access_code=${activity_form?.form_access_link}`
          : "";

        return is_external_task && task_redirect_url
          ? `${task_redirect_url}${queryParams}`
          : task_redirect_url;
      },
    },
    getTaskButtonClass(): string {
      return `state-info ${this.getValidationStatusClass}`;
    },
    getTaskButtonTextClass(): string {
      return `button-simple-text ${this.getValidationStatus} ${
        this.isExternalTaskVisible ? "green-text" : ""
      }`;
    },
    getValidationStatusClass(): string {
      return this.validation === "For Review"
        ? "Validation"
        : this.getValidationStatus;
    },
    getCompleteTaskButtonColor(): string {
      if (this.isExternalTaskVisible) return "#4CAF50";

      return this.notReady ? "#d8d8d8" : "";
    },
    getParticipateTaskButtonColor(): string {
      return "#41c5d3";
    },
    getFormLink(): string | null {
      if (this.fromHistory && this.isGeneral) {
        return null;
      }
      if (this.isGeneral) {
        return this.info?.tasks?.[0]?.activity_form?.link_id || null;
      }
      return this.info?.activity_form?.link_id || null;
    },
    shouldOutlineButton(): boolean {
      return !!this.isExternalTaskVisible;
    },
    isTaskDisabled(): string | boolean {
      return (
        this.validation &&
        this.validation !== "Available" &&
        this.validation !== "In Progress"
      );
    },
  },
  methods: {
    getButtonState(): string {
      const states: Record<string, string> = {
        Completed: "Finalizada",
        Valid: "Válida",
        "For Review": "Em Validação",
        Normal: "Completar Tarefa",
      };
      return states[this.validation] || "Completar Tarefa";
    },
    handleTaskCompletion() {
      const taskId = this.isGeneral ? this.info.tasks[0].id : this.info.id;

      /** Emit event with task ID */
      this.$emit("subTaskBtn", taskId);

      // Update state in store
      this.$store.commit("activityData2/updateRemoveTask", 1);
      this.$store.dispatch("activityData2/setSeenGeneralTask", taskId);

      /** Open modal */
      this.modalName = `${this.successModal}${this.info.id}`;
      this.openModal(this.modalName);
    },
    handleExternalTaskOpening() {
      window.open(this.taskRedirectUrl, "_blank");
    },
  },
});
