



















































































































import config from "@/config";
import chartConfig from "@/config-chart";
import mixins from "vue-typed-mixins";
import { DateTime } from "luxon";

/** Mixins */
import { ChartDataStructure } from "@/mixins/general-mixin";
import { ProfileMixin } from "@/mixins/participant-general-mixin";

/** Components */
import MeasurementsElectricHouseHeading from "@/components/participant/dashboard/measurements-electric-house-heading.vue";
import MeasurementsHeading from "@/components/participant/dashboard/measurements-heading.vue";
import DashboardEquipmentsCard from "@/components/participant/dashboard-equipments-card.vue";
import DashboardActivitiesCard from "@/components/participant/dashboard-activities-card.vue";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import ParticipantDashboardModal from "@/components/participant/participant-dashboard-modal.vue";
import ParticipantLowGasModal from "@/components/participant/modals/participant-low-gas-modal.vue";
import ParticipantLowBottleBatteryModal from "@/components/participant/modals/participant-low-bottle-battery-modal.vue";
import ParticipantSmallInfoCard from "@/components/participant/participant-small-info-card.vue";
import TemperatureHumidityCard from "@/components/general/temperature-humidity-card.vue";
import GasConsumptionCard from "@/components/general/gas-consumption-card.vue";
import ChartCard from "@/components/charts/chart-card.vue";

export interface Monitor {
  id: number;
  sensor: string;
  device_category: number;
  device_manufacture_display_name: string;
  device_manufacture_id: number;
  device_type_id: number;
  device_type_name: string;
  device_metadata: Record<string, unknown>;
  friendly_name: string;
  state: number;
  updated: string;
}

export interface Monitors {
  id: number;
  name: string;
  items: Monitor[];
  meter_count: number;
  meter_online_count: number;
  plug_count: number;
  plug_online_count: number;
  online: number;
  total: number;
}

export default mixins(ProfileMixin).extend({
  name: "ParticipantDashboard",
  components: {
    MeasurementsHeading,
    MeasurementsElectricHouseHeading,
    DashboardEquipmentsCard,
    DashboardActivitiesCard,
    ParticipantNoContentInfoCard,
    ParticipantDashboardModal,
    ParticipantLowGasModal,
    ParticipantLowBottleBatteryModal,
    ParticipantSmallInfoCard,
    TemperatureHumidityCard,
    GasConsumptionCard,
    ChartCard,
  },
  computed: {
    /** Initializes default and persistent values for the chart card.
     * The `consumptionChart` object is passed to the General Mixin to
     * maintain reusability and prevent code duplication. */
    monitors(): Monitors | null {
      const data = this.$store.getters["monitorData/monitorInfo"];
      return data ?? null;
    },
    pageSize(): number {
      return this.$vuetify.breakpoint.width <= 1264 ? 3 : 6;
    },
    hasDashboardData(): boolean {
      return !!this.dashboardData;
    },
    dashboardStructure(): ChartDataStructure {
      const dashboardChart = JSON.parse(
        JSON.stringify(chartConfig.chartBasicStructure)
      );
      dashboardChart.chartTitle = "Diagrama Horário do Dia (kWh)";
      dashboardChart.chartType = "line";
      dashboardChart.chartInfo.id = 1;
      dashboardChart.chartInfo.labels = ["Consumo de Eletricidade"];
      dashboardChart.chartInfo.data = [[]];
      return dashboardChart;
    },
    isHumidityTemperatureVisible(): boolean {
      /** Determine visibility for Temperature and Humidity card,
       * hide it if they have not sensors for humidity and temperature. */
      return this.dashboardData?.has_humidity_temperature_meter;
    },
    isParticipantWithEnergyGasMeter(): boolean {
      return this.dashboardData?.has_energy_gas_meter ?? false;
    },
  },
  created() {
    this.deviceWidth = window.innerWidth;
    this.checkNoContentModal();
    this.consumptionChart = this.dashboardStructure;
    this.retrieveDashboardData(true);
    this.updateDashboardMethod = setInterval(
      this.retrieveDashboardData,
      300000
    );
    /** Get Profile Info to avoid breaking */
    this.$store.dispatch("participantGeneral/getProfileInfo");
  },
  beforeDestroy() {
    clearInterval(this.updateDashboardMethod);
  },
  data() {
    return {
      deviceWidth: 0,
      noContentTitle: config.noContentTitle,
      noContentText: config.noContentText,
      updateDashboardMethod: 0,
      consumptionChart: {} as ChartDataStructure,
      consumptionTimes: [
        {
          title: "Dia atual",
          dates: [] as Date[],
        },
        {
          title: "Período Horário",
          dates: [] as Date[],
        },
      ],
      roomData: [],
    };
  },
  methods: {
    noModalParentAction(): void {
      this.closeModal("no-installation");
      this?.$router?.push("profile");
    },
    checkNoContentModal(): void {
      let no_content_status: boolean = JSON.parse(
        sessionStorage.getItem("no-content-modal") || "true"
      );
      this.$store.commit("toggleModalState", {
        name: "no-content",
        status: no_content_status,
      });
    },
    showLoadingOverlay(): void {
      this.$store.commit("updateState", { loading_overlay: true });
    },
    hideLoadingOverlay(): void {
      this.$store.commit("updateState", { loading_overlay: false });
    },
    updateConsumptionTimes(): void {
      const { energy_consumption_24_hours: consumptionData } =
        this.dashboardData;

      this.consumptionTimes[0].dates = [new Date(consumptionData.today)];
      this.consumptionTimes[1].dates = [
        new Date(`${consumptionData.today}T${consumptionData.start_time}`),
        new Date(`${consumptionData.today}T${consumptionData.end_time}`),
      ];
    },
    updateChartRange(): void {
      const startOfDayMillis = DateTime.now().startOf("day").toMillis();
      this.consumptionChart.chartInfo.rangeValues = {
        min: startOfDayMillis,
        max: startOfDayMillis + 82800000,
      };
    },
    populateConsumptionChart(): void {
      const {
        energy_consumption_24_hours: consumptionData = [],
        energy_production_24_hours: productionData = [],
        energy_gas_consumption_24_hours: gasData = [],
      } = this.dashboardData;

      this.consumptionChart.chartInfo.data = [consumptionData.profile];

      this.addChartDataset(
        productionData?.profile,
        "Produção de Energia",
        "#ebd048",
        {
          top: "rgba(244, 221, 105, 0.5)",
          bottom: "rgba(198, 175, 51, 0)",
        }
      );

      this.addChartDataset(gasData?.profile, "Consumo de Gás", "#777777", {
        top: "rgba(119, 119, 119, 1)",
        bottom: "rgba(198, 175, 51, 0)",
      });

      this.consumptionChart.chartInfo.displayLegend = true;
    },
    addChartDataset(
      profileData: { x: number; y: number }[],
      label: string,
      borderColor: string,
      backgroundColor: { bottom: string; top: string }
    ) {
      if (profileData?.length) {
        const index = this.consumptionChart.chartInfo.data.length++;
        this.consumptionChart.chartInfo.data[index] = profileData;
        this.consumptionChart.chartInfo.labels?.push(label);
        this.consumptionChart.chartInfo.colors.push({
          border: borderColor,
          background: backgroundColor,
        });
      }
    },
    async fetchDashboardInfo() {
      await this.$store.dispatch("dashboardData/getDashboardInfo");
    },
    async retrieveMonitorData() {
      this.getEquipmentData();
    },
    async getEquipmentData() {
      await this.$store.dispatch("monitorData/getMonitorInfo");
    },
    async retrieveDashboardData(initialLoad = false) {
      if (initialLoad) {
        this.showLoadingOverlay();
        this.retrieveMonitorData();
      }
      await this.fetchDashboardInfo();

      const { energy_consumption_24_hours, energy_gas_consumption_24_hours } =
        this.dashboardData;

      if (
        energy_consumption_24_hours?.profile?.length ||
        energy_gas_consumption_24_hours?.profile?.length
      ) {
        this.populateConsumptionChart();
        this.updateConsumptionTimes();
        this.updateChartRange();
        this.consumptionChart.isVisible = true;
      }

      if (initialLoad) {
        this.hideLoadingOverlay();
      }
    },
  },
});
