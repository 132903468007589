var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex py-0 px-0 align-start",attrs:{"fluid":""}},[(_vm.hasDashboardData)?_c('participant-dashboard-modal',{attrs:{"modalName":'no-installation',"modalTitle":_vm.noContentTitle,"modalContent":_vm.noContentText,"modalButtonLabel":'Completar Perfil'},on:{"callParentAction":_vm.noModalParentAction}}):_vm._e(),(_vm.isParticipantWithEnergyGasMeter)?_c('participant-low-gas-modal',{attrs:{"modalName":'participant-low-gas-modal'}}):_vm._e(),(_vm.isParticipantWithEnergyGasMeter)?_c('participant-low-bottle-battery-modal',{attrs:{"modalName":'participant-low-bottle-battery'}}):_vm._e(),(_vm.hasDashboardData)?_c('v-row',{staticClass:"participant-content-area"},[(_vm.isParticipantWithEnergyGasMeter)?_c('measurements-electric-house-heading'):_c('measurements-heading'),_c('v-col',{attrs:{"cols":_vm.isHumidityTemperatureVisible ? 12 : 12,"sm":_vm.isHumidityTemperatureVisible ? 12 : 12,"md":_vm.isHumidityTemperatureVisible ? 8 : 12}},[(!(_vm.consumptionChart && _vm.consumptionChart.isVisible))?_c('participant-no-content-info-card',{attrs:{"icon":'icon-unplugged.svg',"check":0,"size":4,"cardTitle":'Energia Diária',"messageTitle":'Nenhum Medidor Ligado',"messageText":'Os dados serão exibidos assim que a instalação dos medidores esteja concluída.'}}):_vm._e(),(_vm.consumptionChart && _vm.consumptionChart.isVisible)?_c('chart-card',{attrs:{"cardTitle":'Energia Diária',"totalConsumption":_vm.dashboardData.energy_consumption_24_hours.total || 0.0,"totalProduction":_vm.dashboardData.energy_production_24_hours.total,"consumptionTimes":_vm.consumptionTimes,"isMagicFixStyling":true,"charts":[_vm.consumptionChart],"displayArea":'dashboard'}}):_vm._e()],1),(_vm.isHumidityTemperatureVisible)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[(
          !(_vm.dashboardData.real_time_ht && _vm.dashboardData.real_time_ht.length)
        )?_c('participant-no-content-info-card',{attrs:{"check":0,"icon":'icon-temperature.svg',"size":_vm.$vuetify.breakpoint.xs
            ? 4
            : _vm.$vuetify.breakpoint.sm
            ? 4
            : _vm.$vuetify.breakpoint.md
            ? 10
            : _vm.$vuetify.breakpoint.lg
            ? 10
            : 8,"cardTitle":'Temperatura e Humidade',"messageTitle":'Nenhum Sensor Ligado',"messageText":'Os dados serão exibidos assim que a instalação dos sensores esteja concluída.'}}):_vm._e(),(_vm.dashboardData.real_time_ht && _vm.dashboardData.real_time_ht.length)?_c('temperature-humidity-card',{attrs:{"cardTitle":'Temperatura e Humidade',"roomData":_vm.dashboardData.real_time_ht.slice(0, 3)}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('dashboard-equipments-card',{attrs:{"icon":'icon-notifications.svg',"min_heightDT":320,"min_heightMB":320,"size":10,"cardTitle":'Equipamentos',"page_size":_vm.pageSize,"history":_vm.monitors}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('dashboard-activities-card',{attrs:{"dashboard":true,"icon":'icon-credits-missing.svg',"cardTitle":'Atividades'}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }