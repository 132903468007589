var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hasHumidityTemperatureData)?_c('participant-no-content-info-card',{attrs:{"check":0,"icon":'icon-temperature.svg',"size":_vm.$vuetify.breakpoint.xs
      ? 4
      : _vm.$vuetify.breakpoint.sm
      ? 4
      : _vm.$vuetify.breakpoint.md
      ? 10
      : _vm.$vuetify.breakpoint.lg
      ? 10
      : 8,"cardTitle":'Consumo de Gás',"messageTitle":'Nenhum Sensor Ligado',"messageText":'Os dados serão exibidos assim que a instalação dos sensores esteja concluída.'}}):_c('v-card',{staticClass:"custom-card-radius",attrs:{"elevation":"2"}},[(_vm.cardTitle)?_c('v-card-title',{staticClass:"card-title-area text-4 pb-1 ellipsis-break"},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-md-row col-12 pl-5 pr-2 pt-4 pb-5"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }