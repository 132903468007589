





















































import Vue from "vue";
import HomeactivityCard from "./activities/homeactivity-card.vue";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import { ActivityProject, ActivityTask } from "@/store/participant/activity2";

export default Vue.extend({
  name: "DashboardActivitiesCard",
  components: {
    HomeactivityCard,
    ParticipantNoContentInfoCard,
  },

  created() {
    this.getGeneralActivities();
  },

  methods: {
    async getGeneralActivities() {
      await this.$store.dispatch("activityData2/getGeneralTasksNew", {
        excludeCompleted: true,
      });
    },
  },
  computed: {
    activitiesProjects: {
      get(): any {
        const activities = this.$store.getters["activityData2/generalTasksNew"];
        if (!activities) return [];
        return activities.filter((activity: ActivityProject) =>
          activity.tasks.every(
            (task: ActivityTask) =>
              task.submission?.validation_state?.toLowerCase() !== "valid" &&
              task.submission?.validation_state?.toLowerCase() !== "in review"
          )
        );
      },
    },
  },

  props: [
    "icon",
    "size",
    "cardTitle",
    "messageTitle",
    "messageText",
    "roughEdges",
    "dashboard",
  ],
});
