import { ChartDataStructure } from "./mixins/general-mixin";

// This configuration file contains all information regarding the default options that every chart available on this app has-
// this includes the basic structure used to indicate certain features the chart should have or the specific info each chart
// should have. These include things such as legend labels, title, colors available initially, unit type information.
// NOTE: for each page, the ID of the chart should be exclusive. Wouldn't be bad idea to have a static variable to get it and
// from that increment with each use.
// NOTE2: these are all used initially but can drastically change based on their usage and data fetching from the backend.
// some may already have all defined labels for the legend by default (like for example averageConsumptionBarChartData)
// but mostly others this is dynamic and later on incremented
export default {
  // general structure code for charts
  // this is used as a skeleton for every other method in this file as it contains common default values
  // I believe some properties may be missing from here? I should've created an interface for this :')
  // noticeable attribute missing from this object is chartTooltipInfo which is used to give the text in case you want
  // to have a tooltip next to the chart title. This was later on added so it went missing from here
  // there is also a customLabels properties somewhere which is to distinguish different X values from their respective data labels
  chartBasicStructure: {
    isVisible: false,
    chartTitle: "",
    chartType: "line",
    chartTotal: 0,
    chartInfo: {
      id: 0,
      displayLegend: false,
      legendBottom: false,
      rangeValues: {
        max: 1292400000,
        min: 1206000000,
      },
      // By default it's going to be in the hour format as it's the most common one and avoids copy pasting this
      timeInfo: {
        unitType: "hour",
        displayFormats: {
          hour: "HH",
        },
        tooltipFormat: "HH:mm",
      },
      // These are also the default colours on the line chart, at least the first one
      // this should always exist as otherwise it will pick I believe gray to display all data
      // border property should also be mandatory, as it's the default colour it chooses.
      // background object is used when you want to have a gradient colouring. please only use one
      // per chart
      colors: [
        {
          border: "#3bbbc8",
          background: {
            top: "rgba(59, 187, 200, 0.5)",
            bottom: "rgba(255, 255, 255, 0)",
          },
        },
      ],
      tooltipUnit: "kWh",
      // labels to be displayed on the legend. Used to differentiate different data to be visualised on same graph
      labels: [],
      // keep this as the data to be displayed are always an array of array (to display different charts on same graph)
      data: [[]],
    },
  } as ChartDataStructure,
  // START consumption page charts info
  dailyLineChartData(): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Consumo Horário do Dia Anterior (kWh)";
    chartChart.chartType = "line";

    chartChart.chartInfo.id = 1;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartInfo.labels = ["Consumo de Eletricidade do dia Anterior"];
    return chartChart;
  },
  weeklyLineChartData(): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Consumo Horário (kWh)";
    chartChart.chartType = "line";

    chartChart.chartTooltipInfo =
      "Permite comparar um dia da semana específico com o consumo médio diário dessa semana. Na caixa de selecção indique o dia pretendido para a comparação. Pode também selecionar mais do que um dia.";

    chartChart.chartInfo.id = 2;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartInfo.labels = ["Consumo Médio de Eletricidade Semanal"];
    chartChart.chartInfo.colors = [{ border: "#3bbbc8" }];
    return chartChart;
  },
  weeklyBarChartData(): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Consumo Total Diário (kWh)";
    chartChart.chartType = "bar";
    chartChart.chartStack = true;

    chartChart.chartInfo.id = 7;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartInfo.timeInfo = {
      unitType: "day",
      displayFormats: {
        day: "ccc",
      },
      tooltipFormat: "cccc",
    };
    return chartChart;
  },
  anualLineChartData(): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Consumo Horário (kWh)";
    chartChart.chartType = "line";

    chartChart.chartInfo.id = 4;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartTooltipInfo =
      "Permite comparar a média diária mensal com a média diária anual. Na caixa de selecção indique o mês pretendido para a comparação. Pode também selecionar mais do que um mês.";

    chartChart.chartInfo.labels = ["Consumo Médio de Eletricidade Anual"];
    chartChart.chartInfo.colors = [{ border: "#3bbbc8" }];
    return chartChart;
  },
  anualBarChartData(): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Consumo Total Mensal (kWh)";
    chartChart.chartType = "bar";
    chartChart.chartStack = true;

    chartChart.chartInfo.id = 5;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartTooltipInfo =
      "Este gráfico exibe valores do consumo total combinado de todos os aparelhos.";

    chartChart.chartInfo.timeInfo = {
      unitType: "month",
      displayFormats: {
        month: "LLL",
      },
      tooltipFormat: "LLLL yyyy",
    };

    return chartChart;
  },
  applianceChartData(): any {
    return {
      chartTitle: "Consumo Desagregado",
      chartType: "appliance",
      chartTooltipInfo:
        "Compare o consumo dos seus aparelhos, selecionando cada um até a um máximo de cinco, nas caixas de seleção junto ao nome do aparelho.",
      chartAppliances: [],
      chartPagination: 0,
      chartTotalPagination: 5,
      disabled: false,
    };
  },
  // end of consumption page charts info
  // start horários de consumo page charts
  dayBarChartData(availableTimeSlots: string[]): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Consumo de Energia por Intervalos (kWh)";
    chartChart.chartType = "bar";
    chartChart.chartStack = true;

    chartChart.chartInfo.id = 1;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartInfo.timeInfo.unitType = "";
    chartChart.customLabels = availableTimeSlots;

    return chartChart;
  },
  biggestConsumptionBarChartData(
    availableTimeSlots: string[]
  ): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Horário de Maior Consumo (kWh)";
    chartChart.chartType = "bar";
    chartChart.chartStack = true;

    chartChart.chartInfo.id = 2;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartInfo.timeInfo.unitType = "";
    chartChart.customLabels = availableTimeSlots;

    chartChart.chartInfo.labels = ["Horário de Maior Consumo", "Outros"];
    chartChart.chartInfo.colors = [
      { border: "#3bbbc8" },
      { border: "#e0e0e0" },
    ];

    return chartChart;
  },
  averageConsumptionBarChartData(
    availableTimeSlots: string[]
  ): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Horários de Maior Consumo (kWh)";
    chartChart.chartType = "bar";
    chartChart.chartStack = false;

    chartChart.chartInfo.id = 3;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartInfo.timeInfo.unitType = "";
    chartChart.customLabels = availableTimeSlots;
    chartChart.chartTooltipInfo =
      "Pode escolher mais do que uma semana em simultâneo para poder comparar os horários de maior consumo ao longo do tempo. Caso queira isolar algum dos três indicadores apresentados, basta clicar em cima do nome de cada indicador na legenda do fundo do gráfico.";

    chartChart.chartInfo.labels = ["Média", "Dias de Semana", "Fins de Semana"];
    chartChart.chartInfo.colors = [
      { border: "#3bbbc8" },
      { border: "#fdb278" },
      { border: "#5686ef" },
    ];

    return chartChart;
  },
  // end of horários de consumo
  // start quality page charts
  dailyTHChartData(isHumidity: boolean): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartType = "line";

    chartChart.chartInfo.id = 1;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;
    chartChart.chartInfo.tooltipUnit = isHumidity ? "%" : "ºC";

    if (isHumidity) {
      chartChart.chartTitle = "Humidade Horária (%)";
      chartChart.chartInfo.colors = [
        {
          border: "#948eed",
          background: {
            top: "rgba(148, 142, 237, 0.5)",
            bottom: "rgba(255, 255, 255, 0)",
          },
        },
        { border: "#6dbbf6" },
        { border: "#3bbbc8" },
      ];
    } else {
      chartChart.chartTitle = "Temperatura Horária (ºC)";
      chartChart.chartInfo.colors = [
        {
          border: "#5080e9",
          background: {
            top: "rgba(80, 128, 233, 0.5)",
            bottom: "rgba(255, 255, 255, 0)",
          },
        },
        { border: "#6dbbf6" },
        { border: "#3bbbc8" },
      ];
    }
    return chartChart;
  },
  weeklyTHChartData(isHumidity: boolean): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartType = "line";

    chartChart.chartInfo.id = 2;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;
    chartChart.chartInfo.labels = ["Média Semanal"];
    chartChart.chartInfo.tooltipUnit = isHumidity ? "%" : "ºC";

    if (isHumidity) {
      chartChart.chartTitle = "Diagrama Horário de Humidade (%)";
      chartChart.chartInfo.colors = [
        {
          border: "#948eed",
          background: {
            top: "rgba(148, 142, 237, 0.5)",
            bottom: "rgba(255, 255, 255, 0)",
          },
        },
      ];
    } else {
      chartChart.chartTitle = "Diagrama Horário de Temperatura (ºC)";
      chartChart.chartInfo.colors = [
        {
          border: "#5080e9",
          background: {
            top: "rgba(80, 128, 233, 0.5)",
            bottom: "rgba(255, 255, 255, 0)",
          },
        },
      ];
    }

    return chartChart;
  },
  AnnualProductionConsumption(
    availableTimeSlots: string[]
  ): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartType = "line";
    chartChart.chartTitle = "Médias Horárias do Ano";
    chartChart.chartInfo.id = 4;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;
    chartChart.customLabels = availableTimeSlots;
    chartChart.chartInfo.labels = ["Produção", "Consumo"];
    chartChart.chartInfo.colors = [
      {
        border: "#ebd048",
        background: {
          top: "#ebd048",
          bottom: "rgba(255, 255, 255, 0)",
        },
      },
      {
        border: "#3bbbc8",
      },
    ];
    chartChart.chartInfo.data = [[], []];

    return chartChart;
  },
  weeklyProductionConsumption(
    availableTimeSlots: string[]
  ): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartType = "line";
    chartChart.chartTitle = "Médias Horárias da Semana";
    chartChart.chartInfo.id = 5;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;
    chartChart.customLabels = availableTimeSlots;
    chartChart.chartInfo.labels = ["Produção", "Consumo"];
    chartChart.chartInfo.colors = [
      {
        border: "#ebd048",
        background: {
          top: "#ebd048",
          bottom: "rgba(255, 255, 255, 0)",
        },
      },
      {
        border: "#3bbbc8",
      },
    ];
    chartChart.chartInfo.data = [[], []];

    return chartChart;
  },

  annualTotalProductionConsumption(): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Totais Mensais de Produção e Injeção na Rede";
    chartChart.chartType = "mixed-chart";
    chartChart.chartStack = true;

    chartChart.chartInfo.id = 7;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartInfo.timeInfo = {
      unitType: "month",
      displayFormats: {
        month: "LLL",
      },
      tooltipFormat: "LLLL yyyy",
    };

    chartChart.chartInfo.labels = ["Produção Total", "Consumo Total"];
    chartChart.chartInfo.colors = [
      { border: "#ebd048" },
      { border: "#3bbbc8" },
    ];
    chartChart.chartInfo.data = [[], []];

    return chartChart;
  },

  weeklyTotalProductionConsumption(): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartTitle = "Totais Diários de Produção e Injeção na Rede";
    chartChart.chartType = "mixed-chart";
    chartChart.chartStack = true;

    chartChart.chartInfo.id = 6;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;

    chartChart.chartInfo.timeInfo = {
      unitType: "day",
      displayFormats: {
        day: "ccc",
      },
      tooltipFormat: "cccc",
    };

    chartChart.chartInfo.labels = ["Consumo", "Produção"];
    chartChart.chartInfo.colors = [
      { border: "#3bbbc8" },
      { border: "#ebd048" },
    ];
    chartChart.chartInfo.data = [[], []];

    return chartChart;
  },
  dailyProductionConsumption(): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartType = "line";
    chartChart.chartTitle = "Produção e Injeção Horária de Energia";
    chartChart.chartInfo.id = 1;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;
    chartChart.chartInfo.labels = ["Produção", "Consumo"];
    chartChart.chartInfo.colors = [
      {
        border: "#ebd048",
        background: {
          top: "#ebd048",
          bottom: "rgba(255, 255, 255, 0)",
        },
      },
      {
        border: "#3bbbc8",
      },
    ];
    chartChart.chartInfo.data = [[], []];

    return chartChart;
  },
  monthlyTHChartData(isHumidity: boolean): ChartDataStructure {
    const chartChart = JSON.parse(JSON.stringify(this.chartBasicStructure));
    chartChart.chartType = "line";

    chartChart.chartInfo.id = 3;
    chartChart.chartInfo.displayLegend = true;
    chartChart.chartInfo.legendBottom = true;
    chartChart.chartInfo.tooltipUnit = isHumidity ? "%" : "ºC";
    chartChart.chartInfo.labels = [
      "Média diária",
      "Mínimo diário",
      "Máximo diário",
    ];

    chartChart.chartInfo.timeInfo = {
      unitType: "day",
      displayFormats: {
        day: "d",
      },
      tooltipFormat: "DD",
    };

    if (isHumidity) {
      chartChart.chartTitle = "Diagrama Mensal de Humidade (%)";
      chartChart.chartInfo.colors = [
        {
          border: "#948eed",
          background: {
            top: "rgba(148, 142, 237, 0.5)",
            bottom: "rgba(255, 255, 255, 0)",
          },
        },
        { border: "#6dbbf6" },
        { border: "#3bbbc8" },
      ];
    } else {
      chartChart.chartTitle = "Diagrama Mensal de Temperatura (ºC)";
      chartChart.chartInfo.colors = [
        {
          border: "#5080e9",
          background: {
            top: "rgba(80, 128, 233, 0.5)",
            bottom: "rgba(255, 255, 255, 0)",
          },
        },
        { border: "#6dbbf6" },
        { border: "#3bbbc8" },
      ];
    }

    return chartChart;
  },
  // end of quality page
};
